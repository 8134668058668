import { Button } from "@pallet-hq/hegel"
import config from "config"
import { appendQueryParams } from "utils/router"

const AuthButtons = () => (
    <>
        <Button
            variant="secondary"
            size="small"
            onClick={() =>
                window.location.assign(
                    appendQueryParams(config.CANONICAL_URL + "/login", {
                        from: window.location.href,
                    })
                )
            }
        >
            Log in
        </Button>
        <Button
            size="small"
            ml={2}
            onClick={() =>
                window.location.assign(
                    appendQueryParams(config.CANONICAL_URL + "/login", {
                        from: window.location.href,
                    })
                )
            }
        >
            Sign up
        </Button>
    </>
)

export default AuthButtons
