import styled from "@emotion/styled"
import { mq, theme } from "@pallet-hq/hegel"
import { ReactNode } from "react"
import { DesktopOnly } from "components/atoms"
import { useScreenSize } from "utils/hooks/useSize"

type NavbarProps = {
    left: ReactNode
    center: ReactNode
    right: ReactNode
    elevateNavbar?: boolean
}

const Navbar = ({ elevateNavbar = true, ...props }: NavbarProps) => {
    const { isMobile } = useScreenSize()
    return (
        <NavbarContainer elevateNavbar={elevateNavbar || isMobile}>
            <NavbarSectionContainer>{props.left}</NavbarSectionContainer>
            <NavbarSectionContainer style={{ justifyContent: "center" }}>
                <DesktopOnly>{props.center}</DesktopOnly>
            </NavbarSectionContainer>
            <NavbarSectionContainer>{props.right}</NavbarSectionContainer>
        </NavbarContainer>
    )
}

export default Navbar

const NavbarContainer = styled.div<{ elevateNavbar: boolean }>`
    padding: ${theme.space.small};
    position: sticky;
    top: 0px;
    height: 64px;
    box-sizing: border-box;
    background: ${theme.colors.white};
    box-shadow: ${({ elevateNavbar }) =>
        elevateNavbar && `0px 0.5px 2px rgba(0, 0, 0, 0.16)`};
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* positioning over everything else on the page */
    z-index: 2;
`

const NavbarSectionContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mq({ width: ["auto", "100%"] })};
`
