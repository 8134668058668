/* This hash can be used, e.g., to assign a consistent color to a particular label.
 * (https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript)
 */

const hashCode = (s?: string): number =>
    Math.abs(
        s?.split("").reduce((a, b) => {
            a = (a << 5) - a + b.charCodeAt(0)
            return a & a
        }, 0) || 0
    )

export default hashCode
