import styled from "@emotion/styled"
import { Color } from "@pallet-hq/hegel"
import NextImage from "next/image"
import { ReactNode } from "react"
import Imgix, { SharedImigixAndSourceProps } from "react-imgix"
import { getThemeColor } from "theme/utils"
import { generateColor, isBlobURL, isDataURL } from "./utils"

export type ImageProps = Omit<SharedImigixAndSourceProps, "src"> & {
    src?: string | null
    alt?: string
    colorSlug: string
    defaultColor?: Color
    borderRadius?: string
    border?: string
    maxWidth?: string
    opacity?: number
}

const Image = (props: ImageProps) => {
    let rawImg: ReactNode
    if (props.src && (isDataURL(props.src) || isBlobURL(props.src))) {
        rawImg = (
            <NextImage
                src={props.src}
                alt={props.alt ?? "Image"}
                height={props.height}
                width={props.width}
            />
        )
    } else if (props.src) {
        rawImg = <Imgix {...props} src={props.src} />
    }
    return <ImageDiv {...props}>{rawImg}</ImageDiv>
}

const ImageDiv = styled.div`
    height: ${(props: ImageProps) => props.height}px;
    width: ${(props: ImageProps) => props.width}px;
    background-color: ${(props: ImageProps) =>
        props.src
            ? "transparent"
            : props.defaultColor
            ? getThemeColor(props.defaultColor)
            : generateColor(props.colorSlug)};
    overflow: hidden;
    border-radius: ${(props: ImageProps) => props.borderRadius};
    border: ${(props: ImageProps) => props.border};

    & > img {
        max-width: ${(props: ImageProps) => props.maxWidth};
    }

    opacity: ${(props: ImageProps) => props?.opacity};
`

export default Image
