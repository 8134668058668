import styled from "@emotion/styled"
import { mq } from "@pallet-hq/hegel"
import AuthButtons from "components/Navbar/AuthButtons"
import ProfileControl from "components/Navbar/ProfileControl"
import useAuthContext from "state/useAuthContext"
import { useScreenSize } from "utils/hooks/useSize"
import { NavigationControlProps } from "./types"

const AccountControl = (props: NavigationControlProps) => {
    const { loading, authed } = useAuthContext()
    const { isMobile } = useScreenSize()

    return (
        <MenuDiv>
            {!loading &&
                (authed || isMobile ? (
                    <ProfileControl {...props} />
                ) : (
                    <AuthButtons />
                ))}
        </MenuDiv>
    )
}

const MenuDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    ${mq({ width: ["auto", "100%"] })};
`

export default AccountControl
