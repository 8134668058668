import styled from "@emotion/styled"
import { theme } from "@pallet-hq/hegel"
import NextLink from "components/Link/NextLink"
import { ReactNode } from "react"
import { useScreenSize } from "utils/hooks/useSize"

export const DropdownMenuItem = (props: {
    children: ReactNode
    href: string
}) => {
    const { isMobile } = useScreenSize()

    return (
        <NextLink href={props.href} passHref>
            <DropdownMenuAnchor isMobile={isMobile}>
                {props.children}
            </DropdownMenuAnchor>
        </NextLink>
    )
}

export const MenuItemFormatting = `
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: ${theme.space.xsmall} ${theme.space.small}
        ${theme.space.xsmall} ${theme.space.small};
    box-sizing: border-box;
    width: 100%;

    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: ${theme.colors.gray900};
    background: ${theme.colors.white};
`

const DropdownMenuAnchor = styled.a`
    ${MenuItemFormatting}
    &:hover {
        background: ${theme.colors.gray100};
    }

    ${({ isMobile }: { isMobile: boolean }) =>
        isMobile ? "padding: 10px 0; font-size: 16px; font-weight: 500;" : ""}
`

export const VLineSeparator = styled.div`
    height: 1px;
    background: ${theme.colors.gray200};
    margin: ${theme.space.xxsmall} 0;
`
