import styled from "@emotion/styled"
import { textStyles, theme } from "@pallet-hq/hegel"
import Link from "next/link"
import { useRouter } from "next/router"
import { usePalletContext } from "utils/hooks/usePalletContext"
import { useScreenSize } from "utils/hooks/useSize"
import { PalletContext } from "utils/router"

type NavigationMenuItemProps = {
    name: string
    path: string
}

const isOnRoute = (asPath: string, path: string) => {
    const re = new RegExp(`^(/list/([^/]+))?/${path}(/.+)?`)
    return re.test(asPath)
}

const generateHref = (
    asPath: string,
    path: string,
    palletContext: PalletContext
) => {
    const re = new RegExp("/list/([^/]+)/(.+)?")
    const newPath = re.test(asPath)
        ? `/list/${re.exec(asPath)![1]}/${path}`
        : `/${path}`
    return (
        newPath +
        (palletContext.usingFallback ? `?pallet=${palletContext.pallet}` : "")
    )
}

const NavigationItemMenu = (props: NavigationMenuItemProps) => {
    const palletContext = usePalletContext()
    const { asPath } = useRouter()
    const { isMobile } = useScreenSize()
    return (
        <Link
            href={generateHref(asPath, props.path, palletContext)}
            passHref
            legacyBehavior
        >
            <NavLink
                selected={isOnRoute(asPath, props.path)}
                isMobile={isMobile}
            >
                {props.name}
            </NavLink>
        </Link>
    )
}

type NavLinkProps = {
    selected: boolean
    isMobile: boolean
}

export const NavLink = styled.a`
    text-decoration: none;
    margin: ${(props: NavLinkProps) =>
        props.isMobile ? 0 : `0 ${theme.space.xxsmall}`};
    background-color: ${(props: NavLinkProps) =>
        props.selected && !props.isMobile ? theme.colors.gray900 : ""};
    color: ${(props: NavLinkProps) =>
        props.selected && !props.isMobile
            ? theme.colors.white
            : theme.colors.gray900};
    ${(props: NavLinkProps) =>
        props.isMobile ? textStyles.headlineMedium : textStyles.uiSmall600};
    padding: ${(props: NavLinkProps) =>
        props.isMobile ? "" : `${theme.space.xsmall} ${theme.space.small}`};
    border-radius: ${(props: NavLinkProps) => (props.isMobile ? "" : "999px")};
    border-bottom: ${(props: NavLinkProps) =>
        props.selected && props.isMobile
            ? `3px solid ${theme.colors.primary}`
            : ""};
    min-width: fit-content;

    transition: background 0.1s ease-in;

    &:hover {
        ${(props: NavLinkProps) =>
            !props.selected && `background: ${theme.colors.gray100}`};
    }
`

export default NavigationItemMenu
