import styled from "@emotion/styled"
import { Box, theme } from "@pallet-hq/hegel"
import { useEffect, useState } from "react"
import { FiMenu, FiX } from "react-icons/fi"
import Image from "components/Image"
import useAuthContext from "state/useAuthContext"
import { useScreenSize } from "utils/hooks/useSize"
import AccountDropdownMenu from "./AccountDropdownMenu"
import useHelpMenu from "state/useHelpMenu"
import { NavigationControlProps } from "./types"

const ProfileControl = (props: NavigationControlProps) => {
    const [menuIsOpen, setMenuOpen] = useState(false)
    const { isMobile } = useScreenSize()
    const { user } = useAuthContext()
    const { setIsVisible } = useHelpMenu()

    useEffect(() => {
        if (isMobile) setIsVisible(!menuIsOpen)
    }, [isMobile, menuIsOpen, setIsVisible])

    return (
        <>
            <ProfileControlContainer
                menuIsOpen={menuIsOpen}
                isMobile={isMobile}
                onClick={() => setMenuOpen(!menuIsOpen)}
            >
                {menuIsOpen ? (
                    <FiX size={24} />
                ) : (
                    <>
                        {!!user?.introRequests?.totalCount && (
                            <Box width="0px">
                                <NotificationsBadge />
                            </Box>
                        )}
                        <FiMenu size={24} />
                    </>
                )}
                {!isMobile && (
                    <ProfileImage>
                        <Image
                            colorSlug=""
                            src={user?.profileImageUrl}
                            alt="Profile image"
                            height={24}
                            width={24}
                            borderRadius="50%"
                            css={{ objectFit: "cover" }}
                        />
                    </ProfileImage>
                )}
            </ProfileControlContainer>
            {menuIsOpen && <AccountDropdownMenu {...props} />}
        </>
    )
}

const ProfileImage = styled.div`
    margin-left: 2px;
`

const NotificationsBadge = styled.div`
    background: ${theme.colors.red500};
    border: 2px solid ${theme.colors.gold000};
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: relative;
    left: -2px;
`

const ProfileControlContainer = styled.button`
    display: flex;
    cursor: pointer;
    padding: ${theme.space.xxsmall} ${theme.space.xxsmall}
        ${theme.space.xxsmall} ${theme.space.xxsmall};
    border-radius: 999px;
    height: 32px;
    box-sizing: border-box;
    color: ${theme.colors.gray900};
    background-color: ${({
        menuIsOpen,
        isMobile,
    }: {
        menuIsOpen: boolean
        isMobile: boolean
    }) =>
        menuIsOpen && !isMobile ? theme.colors.gray100 : theme.colors.white};
    border: 1px solid
        ${({
            menuIsOpen,
            isMobile,
        }: {
            menuIsOpen: boolean
            isMobile: boolean
        }) =>
            menuIsOpen && !isMobile
                ? theme.colors.gray400
                : theme.colors.white};
    transition: 0.3s;
    ${({ isMobile }: { isMobile: boolean }) =>
        isMobile ||
        `&:hover {
        background-color: ${theme.colors.gray100};
        border: 1px solid ${theme.colors.gray400};
    }`}
`

export default ProfileControl
