import { gql } from "@apollo/client"
import { Box } from "@pallet-hq/hegel"
import { RenderOptions_applylist } from "__generated__/RenderOptions_applylist"
import NavigationMenuItem from "./NavigationMenuItem"

const RenderNavigationItemsFragment = gql`
    fragment RenderOptions_applylist on ApplylistType {
        id
        canInitCollective
        canEdit
        group {
            id
        }
    }
`

const NavigationMenu = ({
    applylist,
}: {
    applylist?: RenderOptions_applylist
}) => {
    if (!applylist) return null

    const canEdit = applylist.canEdit
    const showTalent = !!(
        applylist.group ||
        (applylist.canInitCollective && canEdit)
    )

    return (
        <Box
            display="flex"
            flexDirection={["column", "row"]}
            px={["small", "0px"]}
            py={["medium", "0px"]}
            gridGap={["large", "0px"]}
        >
            <NavigationMenuItem name="Jobs" path="jobs" />
            <NavigationMenuItem name="Companies" path="companies" />
            {showTalent && <NavigationMenuItem name="Talent" path="talent" />}
            {canEdit && <NavigationMenuItem name="Manage" path="manage" />}
            {canEdit && (
                <NavigationMenuItem
                    name="Analytics"
                    path="analytics/dashboard"
                />
            )}
            {canEdit && <NavigationMenuItem name="Settings" path="settings" />}
        </Box>
    )
}

NavigationMenu.fragment = RenderNavigationItemsFragment
export default NavigationMenu
