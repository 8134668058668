import { Box } from "@pallet-hq/hegel"
import { useRouter } from "next/router"
import Link from "next/link"
import config from "config"
import { NavLink } from "page-components/pallet/NavbarLayout/NavigationBar/NavigationMenu/NavigationMenuItem"
import { useScreenSize } from "utils/hooks/useSize"

const ProfileNavigationMenu = () => {
    return (
        <Box
            display="flex"
            flexDirection={["column", "row"]}
            px="small"
            gridGap={["medium", "small"]}
        >
            <MenuItem name="Profile" path="profile" />
            <MenuItem name="Companies" path="profile/companies" />
            <MenuItem name="Intro Requests" path="profile/intros" />
        </Box>
    )
}

export { ProfileNavigationMenu }

const MenuItem = ({ name, path }: { name: string; path: string }) => {
    const { asPath } = useRouter()
    const { isMobile } = useScreenSize()

    return (
        <Link href={`${config.CANONICAL_URL}/${path}`} passHref legacyBehavior>
            <NavLink selected={asPath === `/${path}`} isMobile={isMobile}>
                {name}
            </NavLink>
        </Link>
    )
}
