import { Color, theme } from "@pallet-hq/hegel"
import hashCode from "utils/hashCode"
import { KeyValue } from "utils/types"

export const getThemeColor = (
    color: Color | "" = "",
    defaultColor: Color | string = "white"
): any => {
    const colors: KeyValue<string> = theme.colors

    return colors[color] || colors[defaultColor] || defaultColor
}

export const getTagColors = (s?: string) => {
    const i = hashCode(s)
    const theme_arr = [
        "gray900",
        "gray600",
        "orange",
        "green",
        "blue",
        "red700",
    ]
    return theme_arr[i % theme_arr.length]
}
