import styled from "@emotion/styled"
import { Badge, Box, Divider, Text, theme } from "@pallet-hq/hegel"
import { MobileOnly } from "components/atoms"
import NavigationMenu from "page-components/pallet/NavbarLayout/NavigationBar/NavigationMenu"
import { ProfileNavigationMenu } from "page-components/profile/navigation/Menu"
import useJobPreferences from "state/jobPreferences/useJobPreferences"
import useAuthContext from "state/useAuthContext"
import { useScreenSize } from "utils/hooks/useSize"
import { DropdownMenuItem, MenuItemFormatting } from "./atoms"
import MenuAuthButtons from "./MenuAuthButtons"
import { NavigationControlProps } from "../types"

const AccountDropdownMenu = (props: NavigationControlProps) => {
    const { isMobile } = useScreenSize()
    const { authed, user } = useAuthContext()
    const { setJobPreferencesIsOpen } = useJobPreferences()

    return (
        <AuthDropdownContainer
            isMobile={isMobile}
            hasNavigationMenu={
                !!props.applylist || !!props.showCandidateProfileNavigation
            }
        >
            <MobileOnly>
                {props.applylist && (
                    <NavigationMenu applylist={props.applylist} />
                )}
                {props.showCandidateProfileNavigation && (
                    <ProfileNavigationMenu />
                )}
            </MobileOnly>
            <UserMenuDiv isMobile={isMobile}>
                {user?.email && (
                    <Box>
                        <Text
                            variant={["uiSmall500", "uiTiny500"]}
                            color="gray600"
                            p={[
                                `${theme.space.small} 0`,
                                `${theme.space.xsmall} ${theme.space.small}`,
                            ]}
                        >
                            Signed in as {user.email}
                        </Text>
                        <Divider />
                    </Box>
                )}
                <UserActionMenuDiv isMobile={isMobile}>
                    {authed && (
                        <>
                            {!isMobile && props.applylist && <Divider />}
                            {!!user?.introRequests?.totalCount && (
                                <DropdownMenuItem href="/profile/intros">
                                    Intro requests{" "}
                                    <StyledBadge>
                                        {user?.introRequests.totalCount}
                                    </StyledBadge>
                                </DropdownMenuItem>
                            )}
                            {!!user?.currentTeam && (
                                <DropdownMenuItem
                                    href={`/team/${user.currentTeam?.uuid}`}
                                >
                                    Recruiter dashboard
                                </DropdownMenuItem>
                            )}
                            <DropdownMenuItem href="/profile">
                                Profile
                            </DropdownMenuItem>
                            <JobPreferencesButton
                                isMobile={isMobile}
                                onClick={() => setJobPreferencesIsOpen(true)}
                            >
                                Job preferences
                            </JobPreferencesButton>
                            {!isMobile && <Divider />}
                        </>
                    )}
                    <MenuAuthButtons />
                </UserActionMenuDiv>
            </UserMenuDiv>
        </AuthDropdownContainer>
    )
}

const StyledBadge = styled(Badge)`
    background: ${theme.colors.red400};
    color: ${theme.colors.white};
`

const JobPreferencesButton = styled.button`
    ${MenuItemFormatting};
    cursor: pointer;
    border: none;

    &:hover {
        background: ${theme.colors.gray100};
    }

    ${({ isMobile }: { isMobile: boolean }) =>
        isMobile ? "padding: 10px 0; font-size: 16px; font-weight: 500;" : ""}
`

const AuthDropdownContainer = styled.div<{
    isMobile: boolean
    hasNavigationMenu: boolean
}>`
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: auto;
    justify-content: ${({ isMobile, hasNavigationMenu }) =>
        isMobile && hasNavigationMenu ? "space-between" : "flex-start"};

    top: ${({ isMobile }) => (isMobile ? "64px" : "52px")};
    right: ${({ isMobile }) => (isMobile ? 0 : theme.space.small)};
    min-width: ${({ isMobile }) => (isMobile ? "" : "240px")};
    width: ${({ isMobile }) => (isMobile ? "100vw" : "")};

    //Fallback styling for browsers that do no support dynamic viewport units (Firefox & Chrome)
    height: ${({ isMobile }) => (isMobile ? "calc(100vh - 65px)" : "inherit")};
    height: ${({ isMobile }) => (isMobile ? "calc(100dvh - 65px)" : "inherit")};

    padding: ${({ isMobile }) =>
        isMobile ? 0 : `${theme.space.xsmall} 0 ${theme.space.xsmall} 0`};
    border-radius: ${({ isMobile }) => (isMobile ? 0 : theme.space.xsmall)};

    background-color: ${theme.colors.white};
    box-shadow: ${({ isMobile }) =>
        isMobile ? "none" : "0px 4px 10px rgba(0, 0, 0, 0.16)"};
`

const UserMenuDiv = styled.div<{ isMobile: boolean }>`
    padding: ${({ isMobile }) => (isMobile ? theme.space.small : 0)};
`

const UserActionMenuDiv = styled.div<{ isMobile: boolean }>`
    padding-top: ${({ isMobile }) => (isMobile ? theme.space.xsmall : 0)};
`

export default AccountDropdownMenu
