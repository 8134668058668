import styled from "@emotion/styled"
import { theme } from "@pallet-hq/hegel"
import AuthButtons from "components/Navbar/AuthButtons"
import useAuthContext from "state/useAuthContext"
import { useScreenSize } from "utils/hooks/useSize"
import { MenuItemFormatting } from "./atoms"

const MenuAuthButtons = () => {
    const { authed, logout } = useAuthContext()
    const { isMobile } = useScreenSize()

    return authed ? (
        <LogoutButton isMobile={isMobile} onClick={logout}>
            Log out
        </LogoutButton>
    ) : (
        <AuthButtonsDiv>
            <AuthButtons />
        </AuthButtonsDiv>
    )
}

const LogoutButton = styled.button`
    ${MenuItemFormatting};
    cursor: pointer;
    border: none;

    font-weight: 500;
    color: ${theme.colors.red700};

    &:hover {
        background: ${theme.colors.gray100};
    }

    ${({ isMobile }: { isMobile: boolean }) =>
        isMobile ? "padding: 10px 0; font-size: 16px;" : ""}
`

const AuthButtonsDiv = styled.div`
    display: flex;
    flex-direction: column;
    & button {
        margin: 0px;
        width: 100%;
    }
    & button:last-child {
        margin-top: ${theme.space.small};
    }
`

export default MenuAuthButtons
