import { buildURL, SharedImigixAndSourceProps } from "react-imgix"
import { Color, theme } from "@pallet-hq/hegel"
import hashCode from "utils/hashCode"

export const isBlobURL = (str: string): boolean => /^blob*/.test(str)

export const isDataURL = (s: string): boolean => {
    /*
        Detecting data URLs
        data URI - MDN https://developer.mozilla.org/en-US/docs/data_URIs
        The "data" URL scheme: http://tools.ietf.org/html/rfc2397
        Valid URL Characters: http://tools.ietf.org/html/rfc2396#section2
    */
    const regex =
        /^\s*data:([a-z]+\/[a-z]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-z0-9!$&',()*+,;=\-._~:@/?%\s]*\s*$/i
    return !!s.match(regex)
}

export const generateColor = (s?: string): Color => {
    /*
        The goal of this function is to generate a color in the situation when
        an image source doesn't exist. We create a hash using the string 's'
        and index the theme colors from that hash.
    */
    const keys = Object.keys(theme.colors)

    return (theme.colors as { [key: string]: any })[
        keys[hashCode(s) % keys.length]
    ]
}

export const buildImgixUrl = (
    url: string,
    options: SharedImigixAndSourceProps["imgixParams"] = {}
): string => {
    return buildURL(url, { auto: "format", ...options })
}
